import { cache } from '@solidjs/router';
import { withScope } from '@sentry/solidstart';
import { getApiClient } from './get-api-client';
import { ttlCache } from './cache';
import type { CachedFunction } from '@solidjs/router';
import type { AnyVariables, CombinedError, OperationResult, TypedDocumentNode } from '@urql/core';
import type { OperationDefinitionNode } from 'graphql';

type Options<Data = unknown, Variables extends AnyVariables = AnyVariables> = {
	onData?: (data?: OperationResult<Data, Variables | Record<string, string>>['data']) => unknown;
	onError?: (error: CombinedError) => void;
};

export function cachedQuery<Data = unknown, Variables extends AnyVariables = AnyVariables>(
	query: TypedDocumentNode<Data, Variables>,
	options: Options<Data, Variables> = {},
): CachedFunction<
	(variables: Variables) => Promise<OperationResult<Data, Variables | Record<string, string>>['data']>
> {
	const cacheKey =
		(query.definitions.find((def) => def.kind === 'OperationDefinition') as OperationDefinitionNode)?.name?.value ??
		query.toString();
	const cacheFn = cache(
		async (variables: Variables): Promise<OperationResult<Data, Variables | Record<string, string>>['data']> => {
			const cachedData: Data | undefined = ttlCache?.get<Data>(cacheFn.keyFor(variables));
			if (cachedData) {
				return cachedData;
			}
			let handled = false;

			try {
				const res = await getApiClient().query(query, variables);

				if (res.error) {
					if (options.onError) {
						handled = true;
						options.onError(res.error);
						return;
					}

					throw res.error;
				}

				ttlCache?.set(cacheFn.keyFor(variables), res.data);

				return res.data;
			} catch (e) {
				if (!handled) {
					withScope((scope) => {
						scope.setExtra('cacheKey', cacheKey);
						scope.captureException(e);
					});
				}
				throw e;
			}
		},
		cacheKey,
	);

	return cacheFn;
}
